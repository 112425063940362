<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template>
      <!-- BODY -->
      <div class="ml-2 mt-2">
        <label
          >ID
          <span class="font-weight-bolder">{{
            suscripcionSelected.id
          }}</span></label
        >
        <h3 class="number-color text-uppercase font-weight-bolder">
          {{ suscripcionSelected.cliente }}
        </h3>

        <label>Creado el {{ suscripcionSelected.fecha }}</label>
      </div>
      <div v-if="suscripcionSelected.estado == 'Cancelada'" class="ml-2">
        <b-badge class="pill-style" pill variant="light-danger ">
          Cancelada
        </b-badge>
      </div>
      <div v-if="suscripcionSelected.estado == 'Activa'" class="ml-2">
        <b-badge class="pill-style" pill variant="light-success ">
          Activa
        </b-badge>
      </div>

      <!-- Primer cuadro -->
      <!--  <div class="ml-2 color-container pl-3 py-2">
        <b-row>
          <div>
            <b-row class="mb-1">
              <b-col>
                <feather-icon
                  icon="UserIcon"
                  size="16"
                  class="text-body align-middle"
                />
                <label class="font-weight-bolder ml-1">Tipo de Documento</label>
              </b-col>
            </b-row>

            <b-row class="mb-1 align-items-center">
              <b-col>
                <feather-icon
                  icon="AtSignIcon"
                  size="16"
                  class="text-body align-middle"
                />
                <label class="font-weight-bolder ml-1"
                  >{{suscripcionSelected.cliente}}</label
                >
              </b-col>
            </b-row>

            <b-row class="mb-1 align-items-center">
              <b-col>
                <feather-icon
                  icon="PhoneIcon"
                  size="16"
                  class="text-body align-middle"
                />
                <label class="font-weight-bolder ml-1">+56232109653</label>
              </b-col>
            </b-row>
          </div>
          <div>
            <b-row>
              <b-col>
                <label class="ml-4">37.299.317</label>
              </b-col>
            </b-row>
          </div>
        </b-row>
      </div> -->

      <!-- Segundo cuadro -->
      <div class="ml-2 color-container pl-3 py-2">
        <b-row>
          <!-- Icono y Título -->
          <div>
            <b-row class="mb-1">
              <b-col>
                <feather-icon
                  icon="RotateCwIcon"
                  size="16"
                  class="text-body align-middle"
                />
                <label class="font-weight-bolder ml-1"
                  >Periodicidad de cobro</label
                >
              </b-col>
            </b-row>

            <!-- <b-row class="mb-1 align-items-center">
              <b-col>
                <feather-icon
                  icon="CalendarIcon"
                  size="16"
                  class="text-body align-middle"
                />
                <label class="font-weight-bolder ml-1">Duración</label>
              </b-col>
            </b-row> -->

            <!--  <b-row class="mb-1 align-items-center">
              <b-col>
                <feather-icon
                  icon="RefreshCcwIcon"
                  size="16"
                  class="text-body align-middle"
                />
                <label class="font-weight-bolder ml-1"
                  >Reintegro de cobro</label
                >
              </b-col>
            </b-row> -->

            <b-row class="mb-1 align-items-center">
              <b-col>
                <feather-icon
                  icon="StopCircleIcon"
                  size="16"
                  class="text-body align-middle"
                />
                <label class="font-weight-bolder ml-1">Moneda</label>
              </b-col>
            </b-row>

            <b-row class="mb-1 align-items-center">
              <b-col>
                <feather-icon
                  icon="DollarSignIcon"
                  size="16"
                  class="text-body align-middle"
                  @click="ver(suscripcionSelected)"
                />
                <label class="font-weight-bolder ml-1">Monto</label>
              </b-col>
            </b-row>
          </div>

          <!-- Contenido -->
          <div>
            <b-row class="mb-1">
              <b-col>
                <label class="contenido-tab">-</label>
              </b-col>
            </b-row>

            <!-- <b-row class="mb-1">
              <b-col>
                <label class="contenido-tab">20/05/2021 - Indefinido</label>
              </b-col>
            </b-row> -->

            <!-- <b-row class="mb-1">
              <b-col>
                <label class="contenido-tab">Calendarizado</label>
              </b-col>
            </b-row> -->

            <b-row class="mb-1">
              <b-col>
                <label class="contenido-tab">{{
                  suscripcionSelected.monto.moneda
                }}</label>
              </b-col>
            </b-row>

            <!-- <div id="contenido-tab-monto"> -->
            <b-row class="mb-1 align-items-center">
              <b-col>
                <b-row>
                  <!-- <div>
                                         

                      <b-row>
                        <b-col>
                          <label class="font-weight-bolder ml-2">Total</label>
                        </b-col>
                      </b-row>
                    </div> -->
                  <div>
                    <b-row class="align-items-center">
                      <b-col>
                        <label class="margencantidad contenido-tab">
                          {{ suscripcionSelected.monto.cantidad }}</label
                        >
                      </b-col>
                    </b-row>
                  </div>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <!-- <div id="loading-bg" v-if="loading">
            <div class="loading">
              <div class="effect-1 effects"></div>
              <div class="effect-2 effects"></div>
              <div class="effect-3 effects"></div>
            </div>
          </div> -->
        </b-row>
      </div>

      <div class="ml-2 mb-1">
        <b-button
          @click="irTransacciones(suscripcionSelected.id)"
          variant="outline-secondary"
          :disabled="$store.state.appConfig.loading"
          class="suscription-options"
        >
          Ver Transacciones
        </b-button>

        <!-- <b-button
          class="suscription-options unsuscribe"
          @click="modalCancelarShow = !modalCancelarShow"
          variant="outline-secondary"
          :disabled="loading"
        >
          Desuscribir
        </b-button> -->
      </div>
      <!-- <b-modal
        id="modal-Cancelar"
        body-bg-variant="light-warning"
        v-model="modalCancelarShow"
        hide-footer
        hide-header
        centered
        class="w-50"
        ref="modal-Cancelar"
      >
        <label id="label-confirmacion-cancelar">
          ¿ Seguro que quieres Eliminar esta suscripción?
        </label>
        <b-row class="justify-content-center">
          <div id="loading-bg" v-if="loading">
            <div class="loading">
              <div class="effect-1 effects"></div>
              <div class="effect-2 effects"></div>
              <div class="effect-3 effects"></div>
            </div>
          </div>
          <b-col>
            <b-button
              variant="warning"
              class="mr-1 modal-button-size shadow-none"
              @click="hideCancelarModal"
            >
              No
            </b-button>
             <router-link to="/dashboard/suscripciones">
             <b-button
              variant="outline-warning"
              class="modal-button-size shadow-none"
              @click="desuscribir(suscripcionSelected.id)"
            >
              Sí
            </b-button>
             </router-link>
           </b-col>
        </b-row>
      </b-modal>  -->
    </template>
  </b-sidebar>
</template>

<script>
import {
  BRow,
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BBadge,
  BIcon,
  BIconstack,
  BootstrapVue,
  BootstrapVueIcons,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { ref } from '@vue/composition-api';
import { required, alphaNum, email } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import countries from '@/@fake-db/data/other/countries';
import store from '@/store';
import { integer } from 'vee-validate/dist/rules';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import axios from 'axios';

export default {
  components: {
    BRow,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BBadge,
    BIcon,
    BIconstack,
    BootstrapVueIcons,
    BootstrapVue,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
    suscripcionSelected: { type: Object, required: true },
  },

  methods: {
    irTransacciones(id) {
      this.$router.push({
        name: 'transacciones-suscripcion',
        params: { idSuscripcion: id },
      });
    },
   
    hideCancelarModal() {
      this.$refs['modal-Cancelar'].hide();
    },
    showUnsuscribeToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: '¡Se ha eliminado la suscripción!',
          icon: 'CheckIcon',
          variant,
        },
      });
    },
    showUnsuscribeErrorToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'La suscripción ya fue eliminada previamente',
          text: 'Recargue la pagina, por favor',
          icon: 'XIcon',
          variant,
        },
      });
    },
  },

  data() {
    return {
      // isAddNewUserSidebarActive: false,
      // modalCancelarShow: false,
      // loading: false,
      required,
      alphaNum,
      email,
      countries,
    };
  },
  setup(props, { emit }) {
    const blankUserData = {
      fullName: '',
      username: '',
      email: '',
      role: null,
      currentPlan: null,
      company: '',
      country: '',
      contact: '',
    };
    const isAddNewUserSidebarActive = ref(false);
    const userData = ref(JSON.parse(JSON.stringify(blankUserData)));
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData));
    };

    const onSubmit = () => {
      store.dispatch('app-user/addUser', userData.value).then(() => {
        emit('refetch-data');
        emit('update:is-add-new-user-sidebar-active', false);
      });
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetuserData
    );

    return {
      userData,
      onSubmit,
      isAddNewUserSidebarActive,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }

  .color-container {
    background: #eeeeee;
    width: 90%;
    margin: 16px 16px;
    border-radius: 8px;
  }
  .suscription-options:hover {
    color: #fff;
    background: #e21a6b;
    opacity: 0.8;
  }

  .icon-rotate {
    transform: rotate(45deg);
  }

  .number-color {
    color: black;
  }

  .contenido-tab {
    margin-left: 64px;
  }

  #contenido-tab-monto {
    margin-left: 72px;
  }
  .margencantidad {
    margin-left: 90px;
  }
}
.unsuscribe {
  margin-left: 30px;
}
.modal-button-size {
  width: 166px;
}
#label-confirmacion-cancelar {
  margin-left: 15%;
  margin-bottom: 16px;
  color: $warning !important;
  font-weight: bold;
  font-size: 14px;
}
//SPINNER
#loading-logo {
  width: 50%;
}
#loading-bg {
  width: 100%;
  height: 100%;
  background: transparent;
  display: block;
  position: absolute;
}
.loading-logo {
  position: absolute;
  left: calc(46% - 45px);
  top: 40%;
}
.loading {
  position: absolute;
  left: calc(50% - 35px);
  top: 50%;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 3px solid transparent;
}
.loading .effect-1,
.loading .effect-2 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid rgba(0, 26, 87, 1);
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading .effect-1 {
  animation: rotate 1s ease infinite;
}
.loading .effect-2 {
  animation: rotateOpacity 1s ease infinite 0.1s;
}
.loading .effect-3 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid rgba(0, 26, 87, 1);
  -webkit-animation: rotateOpacity 1s ease infinite 0.2s;
  animation: rotateOpacity 1s ease infinite 0.2s;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading .effects {
  transition: all 0.3s ease;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes rotateOpacity {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 0.1;
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
    opacity: 1;
  }
}
</style>
